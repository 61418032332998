import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useQueryParam, StringParam } from "use-query-params"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from '../../../layouts'
import { Canon, Body } from '../../../components/elements/typography'
import Grid from '../../../components/elements/grid'


const ArticleBlock = ({ key, url, title, description, img}) => {

  const image = getImage(img)

  return (
    <Link key={key} to={`/${url}`} css={{
      backgroundColor: `#fff`,
      boxShadow: `0 1px 2px rgba(0,0,0,0.15)`,
      textDecoration: `none`,
      transition: `margin 0.5s, background-color 0.5s`,
      margin: `0.5rem`,
      '&:hover': {
        margin: `0`,
        backgroundColor: `#fff`
      },
      '.img': {
        boxShadow: `0 0 200px rgba(0,0,0,0.9) inset`,
      }
    }}>
      <div css={{
        width: `100%`,
      }}> 
          <GatsbyImage image={image} layout="fullWidth" aspectRatio={16 / 9} alt="test" /> 
      </div>
      <div css={{padding: `1rem 1.5rem 2rem 1.5rem`}}>
        <h2 css={{color: `#182852`, marginBottom: `0`}}>{title}</h2> 
        <Body>{description}</Body>
      </div>
    </Link>
  )
}






const WellnessArticlesPage = ({ data }) => {

  const [tag, setTag] = useQueryParam("tag", StringParam)


  const blogPosts = data.allMdx.nodes
    .filter(post => post.frontmatter.tags.includes(tag || 'all'))
    .map(i => (
      <ArticleBlock key={i.id} url={i.slug} title={i.frontmatter.title} description={i.frontmatter.description} img={i.frontmatter.featuredImage}/>
  ))

  return (
    <Layout isSubMenuOpen={false} openAccountLink={true}>

      <Grid.FullWidthSection css={{ margin: `6rem 0`}}>
        <Grid.Block start='2' span='12' css={{textAlign: `center`}}>
        <Canon>Financial Wellness Articles </Canon>
        </Grid.Block>
        <Grid.Block start='2' span='12'>
            <div css={{
                display: `grid`,
                gridTemplateColumns: `1fr 1fr 1fr`,
                padding: `0 2rem`,
                gridGap: `3rem`
              }}>
            {blogPosts}
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>
    </Layout>
  )
}

export const pageQuery = graphql`
  query WellnessBlogPostQuery {
    allMdx {
      nodes {
        id
        slug
        frontmatter {
          title
          description
          tags
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: BLURRED
                formats: [AUTO, WEBP]  
              )
            }
          }
        }
        
      }
    }
  }
`

export default WellnessArticlesPage